.text-and-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  background-color: var(--light-gray);

  @media(max-width: 700px) {
    grid-template-columns: 1fr;
  }

  &--image {
    width: 100%;

    .reverse & {
      order: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--text {
    padding: 3rem 3rem;
    align-self: center;
    max-width: 555px;

    @media(max-width: 700px) {
      padding: 2rem 3rem;
    }

    @media(max-width: 500px) {
      padding: 1rem 2rem;
    }

    .reverse & {
      justify-self: flex-end;
    }
  }
}