.btn {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  padding: 0.6rem 2rem;
  border-radius: 40px;
  display: inline-block;
  text-decoration: none;
  transition: filter 0.5s ease-out;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    transition: filter 0.1s ease-out;
    filter: brightness(120%);
  }
}