@use './typography.scss';

.campaign-recipe {
  background-color: #F5F5F5;
  padding-bottom:1em;

  header {
    position: relative;
  }

  &--recipe-image {
    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 65%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      z-index: 1;
    }
  }

  &--product-image {
    position: absolute;
    max-width: 60%;
    height: 110%;
    z-index: 1;
    top: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &--product-bar {
    width: 100%;
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    padding: 1.5rem;
    justify-self: center;
    align-self: flex-start;
    text-align: center;
    box-sizing: border-box;

    br {
      content: ' | ';

      &:after {
        content: ' | ';
      }
    }
  }

  h1 {
    padding: 1em 20px;
    text-align: center;
  }
  
  .btn {
    margin:1em auto;
    display: block;
    width: calc(100% - 40px);
    box-sizing: border-box;

    &.recipe-btn {
      background-color: #c40200;
    }
  }
}

.ingredients-section-wrapper {
  margin: 0 20px 2em;
  border-radius: 10px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
}
.ingredients-section {

  h3 {
    text-align: center;
    @include typography.small-headline;
    margin:1em 0 0.5em;
  }

  &:first-child {
    h3 {
      width:calc(100% + 10px);
      translate: -5px;
      background-color: #F5F5F5;
      margin:0;
      line-height: 2.5em;
    }
  }
}


.ingredients-list {
  margin:0;
  padding:0;

  &--ingredient {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 20px;
    position: relative;
    &:nth-child(2n+1) {
      background-color: #FFF;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    &--measurement{
      font-weight: 300;
      margin-right: 5px;
      width: 50px;
    }
    &--name{
      // flex: 1;
      @include typography.headlineFont;
    }
    &--details{
      flex:1;
      font-style: italic;
      font-weight: 300;
      @include typography.bodyFont;
    }
  }
}

