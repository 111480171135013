.selected-campaigns {
  background-color: #f2f2f2;
  padding: 3rem 0 4rem;

  &--headline {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 2rem;
  }

  ul {
    display: flex;
    justify-content: center;
    margin: 13vw auto 0;
    padding: 0;
    list-style: none;


    @media (min-width:1240px) {
      margin: 10rem auto 0;
    }


    @media (max-width:800px) {
      flex-direction: column;
      margin: auto;
      align-items: center;
    }


    li {
      margin: 0 15px;
      max-width: 360px;
      border-radius: 10px;
      background: white;
      box-shadow: 0px 3px 3px rgb(0 0 0 / 10%);
      padding: 0 1.5rem 1.5rem;
      box-sizing: border-box;
      text-align: center;

      @media (max-width:800px) {
        margin: 5rem 0 2rem;
        max-width: 350px;
      }

      h3 {
        margin: 1rem 0 0.5rem;

      }

      p {
        margin-top: 0;
      }

      picture {
        display: block;
        translate: 0 -33%;
        margin-bottom: -33%;

        @media (max-width:800px) {
          translate: 0 -25%;
          margin-bottom: -25%;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }



    }

  }

}