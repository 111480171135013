@mixin bodyFont {
    font-family: var(--body-font);
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
}

@mixin headlineFont {
    font-family: var(--headline-font);
    text-rendering: geometricPrecision;
    margin: 0;
    font-weight: lighter;
    letter-spacing: -0.01em;
}

@mixin large-headline {
    @include headlineFont();
    font-size: 40px;
    line-height: 1.28;
}

@mixin medium-headline {
    @include headlineFont();
    font-size: 32px;
    line-height: 1.28;
}
@mixin small-headline {
    @include headlineFont();
    font-size: 22px;
    line-height: 1.28;
}

@mixin main-body {
    @include bodyFont();
}

@mixin small-body {
    @include bodyFont();
    font-size: 0.875em
}

@mixin x-small-body {
    @include bodyFont();
    font-size: 0.75em;
}

.large-headline {
    @include headlineFont();

}



h1,
h2,
h3 {
    @include headlineFont();
    line-height: 1.09;
}

h2 {
    line-height: 1.21;
    font-size: 2.5em;

    @media (max-width: 445px) {
        font-size: 8vw;
    }
}

h5 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
}

body,
p {
    @include bodyFont();
}

label,
input {
    @include bodyFont();
    font-size: 16px;
}