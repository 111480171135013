:root {
  --body-font: 'Abel', sans-serif;
  --headline-font: 'Roboto', sans-serif;
  --submit-color: #333333;
  --submit-color-text: #FFFFFF;
  --light-gray: #f2f2f2;
}

body {
  padding: 0;
  margin: 0;
}

footer {
  background-color: #333333;
  color: #FFFFFF;
  padding: 8rem 0 5rem;

  @media (max-width:700px) {
    padding: 3rem 0 3rem;
  }

  a, a:hover, a:active, a:visited {
    color:#ffffff;
  }

  .footer {

    &--logo {
      width: 100%;
      max-width: 150px;
      margin: 0;

      svg g {
        fill: white;
      }
    }

    &--some {
      display: flex;
      margin: 0 -10px;
      width: 120px;

      figure {
        margin: 0 5px;
        width: 100%;
        display: inline-block;
      }

      &--insta {
        svg {
          background-color: white;
          border-radius: 100px;
          box-shadow: inset 1px 0px 0px 2px #333333;
        }
      }
    }
  }



  .container {
    display: grid;
    gap: 2rem;
    grid-template-columns: 150px 1fr;
    align-items: center;

    @media (max-width:700px) {
      grid-template-columns: 1fr;
      justify-items: center;
      text-align: center;

    }

    h3 {
      margin: 0.2rem 0
    }

    p {
      margin: 0;

    }
  }
}

ul {
  margin-block-start: 1em;
  padding-inline-start: 1.2em;
}

input[type="email"],
input[type="text"],
input[type="tel"] {
  display: inline-block;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 0;
  margin: 0;
  box-sizing: border-box;

  &:invalid {
    border: 2px solid red;
  }
}

button {
  position: relative;
  background-color: var(--submit-color);
  color: var(--submit-color-text);
  display: inline-block;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0;
  font-size: 1rem;
  min-height: 40px;
  margin: 0;
  box-sizing: border-box;
}