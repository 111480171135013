.cookie-age-consent {
  position: fixed;
  top:0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color:rgb(0 0 0 /0.8);

  display: none;
  justify-content: center;
  align-items: center;

  &.show {
    display: flex;
  }

  .modal {
    background-color: white;
    padding:4rem;
    border-radius: 10px;
    margin: 2rem;
    max-width: 500px;
    width:100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    button {
      margin-top:2rem;
      align-self: center;
      cursor: pointer;
    }
  }
}