.newsletter-signup {
  padding: 4rem 0;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  text-align: center;

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &--form {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    max-width: 540px;

    .done & {
      display: none;
    }

    .sending & {

      button {
        color: var(--submit-color);

        .spinner {
          opacity: 1;
        }

      }
    }

    button {
      border-radius: 0 50px 50px 0;
      transition: color 0.1s ease-out;

      .spinner {
        opacity: 0;
        transition: opacity 0.1s ease-out;
      }
    }

    input {
      flex-grow: 1;
      border-radius: 50px 0 0 50px;
    }
  }

  &--done {
    display: none;

    .done & {
      display: block;
    }
  }

  &--error {
    display: none;

    .error & {
      display: block;
    }
  }
}