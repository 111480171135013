.hero {
  position: relative;
  display: flex;
  overflow: hidden;

  &.full-size {
    min-height: 100vh;
  }

  &.medium-size {
    min-height: 60vh;
  }

  &.small-size {
    min-height: 30vh;
  }

  img {
    display: block;
  }

  &--background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transform-style: preserve-3d;
    }
  }

  &--content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    padding: 3rem 0;

    &.frontpage {
      grid-template-columns: 1fr;
    }

    @media (max-width:800px) {
      grid-template-columns: auto;
      grid-auto-flow: unset;
    }

    &--logo {
      align-self: center;
      justify-self: center;
      max-width: 500px;
      margin: 0;
      width: 100%;

      svg g {
        fill: white !important;
      }
    }

    &--info {
      justify-self: center;
      align-self: center;

      @media (max-width:800px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &--headline {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }

    &--text {
      margin: 2.5rem 0;
      display: block;
    }

    &--product {
      grid-column: 2;
      justify-self: center;
      align-self: center;
      width: 100%;

      @media (max-width:800px) {
        grid-column: 1;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &--bar {
      grid-column: 1/3;
      position: relative;
      color: var(--primary-text-color);
      width: 100%;
      padding: 1.5rem;
      justify-self: center;
      align-self: flex-start;
      text-align: center;
      box-sizing: border-box;

      @media (max-width:800px) {
        grid-column: 1;
        margin-top: 2rem;
      }

      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--primary-color);
        opacity: 0.3;
        top: 0;
        left: 0;
        z-index: -1;
      }

      br {
        content: ' | ';

        &:after {
          content: ' | ';
        }
      }
    }
  }
}