.ready {
  .header--bar {
    transition: transform 0.3s ease-out;
  }
}

.header {
  position: fixed;
  width: 100%;
  z-index: 100;

  @media (max-width:600px) {
    margin-top: calc(var(--wp-admin--admin-bar--height) * -1);
  }

  &.show {
    .header--bar {
      transform: translateY(0%);
    }

    .logo {
      svg g {
        fill: #111111;
      }
    }
  }

  &--bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 1.2rem 0;
    transform: translateY(-100%);

    @media (max-width:900px) {
      justify-content: flex-end;
    }

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      backdrop-filter: blur(7px);
      background-color: rgba(255, 255, 255, 0.5);
      z-index: -1;
    }

    a {
      @media (max-width:900px) {
        margin: 0 1.2rem;
      }
    }
  }

  .logo {
    position: fixed;
    z-index: 1;
    left: 30px;
    width: 150px;
    translate: 0 22px;

    @media (max-width:430px) {
      width: 112px;
      left: 10px;
      translate: 0 27px;
    }

    // @media (max-width:600px) {
    //   position: initial;
    // }

    svg g {
      transition: fill 0.3s ease-out;
      fill: white
    }
  }
}