.textblock {
  padding: 3.75rem 1rem 3.5em;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 0 0.3em 0;
  }
}