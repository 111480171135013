.spinner {
  animation: rotate 1s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 20px;
  height: 20px;

  & .path {
    stroke: rgb(255, 255, 255);
    stroke-linecap: round;
    animation: dash 0.75s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}