@import url('https://fonts.googleapis.com/css2?family=Abel&family=Roboto:wght@700&display=swap');


// @font-face {
//     font-family: 'Futura';
//     font-style: normal;
//     font-stretch: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url('./fonts/FuturaPTBook.woff2') format('woff2'), /* Super Modern Browsers */
//          url('./fonts/FuturaPTBook.woff') format('woff'), /* Modern Browsers */

// }

// @font-face {
//     font-family: 'Kepler';
//     font-style: normal;
//     font-stretch: normal;
//     font-weight: lighter;
//     font-display: swap;
//     src: url('./fonts/kepler-std-light.woff2') format('woff2'), /* Super Modern Browsers */
//          url('./fonts/kepler-std-light.woff') format('woff'), /* Modern Browsers */
// }